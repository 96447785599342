import React from 'react'
import RegistryBackground from '../assets/backgrounds/registry-background.webp'
import PlayerCard from '../assets/photos/registry-player-card.webp'
import BottomBorder from '../assets/photos/registry-bottom-border.webp'
import RegistryVenmo from '../assets/photos/registry-venmo.webp'

import { REGISTRY_INFO, VENMO_INFO } from '../../common/Strings'

function Registry() {
  return (
    <div
      className='flex flex-col items-center text-2xl overflow-hidden'
      style={{
        backgroundImage: `url(${RegistryBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensure it covers the full viewport height
        width: '100%', // Ensure it covers the full width
      }}
    >
      <div className='w-[1200px] 3xl:w-[1600px] 4xl:w-[1800px] h-full flex flex-col px-10 3xl:px-20 items-center pt-[120px]'>
        <p className='flex text-game-header-yellow text-8xl 3xl:text-10xl'>REGISTRY</p>
        <div className='flex flex-row relative w-full items-center justify-center px-20 font-sans'>
          <img src={PlayerCard} alt='' className='flex h-[500px] 3xl:h-[600px] 4xl:h-[700px] w-auto' />
          <div className='absolute right-20 w-[300px] 3xl:w-[400px] 4xl:w-[500px] flex flex-col items-end text-right text-white text-lg 3xl:text-xl 4xl:text-2xl'>
            <p>{REGISTRY_INFO}</p>
            <p className='mt-8'>{VENMO_INFO}</p>
            <img src={RegistryVenmo} alt='' className='mt-8 w-24 h-24 3xl:w-36 3xl:h-36 4xl:w-48 4xl:h-48' />
          </div>
        </div>
        <img src={BottomBorder} alt='' className='h-auto w-full' />
      </div>
    </div>
  )
}

export default Registry
