import React from 'react'
import Background from '../../assets/img/registry/registry.webp'
import { HBSHeader, MundayHeader } from './constants/fontConstants'
import { REGISTRY_INFO, VENMO_INFO } from '../../common/Strings'

const Registry = () => {
  const textClass = 'text-2xl text-ja-white text-right'

  return (
    <div
      id='registry'
      className='scroll-mt-0 text-gray-800 flex flex-row items-center justify-center w-full'
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Main Headline */}
      <div className='w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl  px-10 py-16'>
        <div className='flex flex-col items-center justify-start h-[600px]'>
          <div className='flex flex-row items-center justify-center md:justify-end w-full px-10 md:px-0 text-ja-white'>
            <h1 className={MundayHeader}>RE</h1>
            <h1 className={HBSHeader}>G</h1>
            <h1 className={MundayHeader}>ISTRY</h1>
          </div>
          <div className='w-full flex justify-end items-start mt-14'>
            <div className='flex flex-col w-2/3 md:w-1/3 justify-center items-end'>
              <p className={`${textClass} mb-4`}>
                {REGISTRY_INFO}
              </p>
              <p className={`${textClass} mb-6`}>{VENMO_INFO}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registry
